import Vue from 'vue'
import VueRouter from "vue-router"
import token from "./token"

Vue.use(VueRouter)

const router = new VueRouter({
	routes: [
		/* 前端首页 */
		{
			path: '/',
			name: 'fIndex',
			component: () => import('./views/front/Index.vue'),
			meta: {
				title: '首页',
				requireLogin: false
			}
		},
		/* 栏目单页主页 */
		{
			path: '/channel_detail/:id',
			name: 'channel_detail',
			component: () => import('./views/front/ChannelDetail.vue'),
			meta: {
				requireLogin: false
			}
		}, 
		/* 栏目列表主页 */
		{
			path: '/list_detail/:id',
			name: 'list_detail',
			component: () => import('./views/front/ListDetail.vue'),
			meta: {
				requireLogin: false
			}
		},
		/* 详情页 */
		{
			path: '/detail',
			name: 'detail',
			component: () => import('./views/front/Detail'),
			meta: {
				requireLogin: false
			}
		}, 
		/* 业务景区详情页 */
		{
			path: '/scenic_detail',
			name: 'scenic_detail',
			component: () => import('./views/front/ScenicDetail.vue'),
			meta: {
				requireLogin: false
			}
		},
		/* 软件成果详情页 */
		{
			path: '/software_detail',
			name: 'software_detail',
			component: () => import('./views/front/SoftwareDetail.vue'),
			meta: {
				requireLogin: false
			}
		},
		
		/* 后台登录页 */
		{
			path: '/admin/login',
			name: 'login',
			component: () => import('./views/admin/Login'),
		},
		/* 后台主页 */
		{
			path: '/admin/index',
			name: 'index',
			component: () => import('./views/admin/Index'),
			children: [
				//个人信息
				{
					path: '/admin/userinfo',
					name: 'userinfo',
					component: () => import('./views/admin/authority/user/UserInfo.vue'),
				},
				//用户管理
				{
					path: '/admin/authority/userindex',
					name: 'userindex',
					component: () => import('./views/admin/authority/user/UserIndex.vue'),
				}, 
				//日志管理
				{
					path: '/admin/authority/loggerindex',
					name: 'loggerindex',
					component: () => import('./views/admin/authority/logger/LoggerIndex.vue'),
				}, 
				//内容管理-栏目管理
				{
					path: '/admin/content/channelindex',
					name: 'channelindex',
					component: () => import('./views/admin/content/channel/ChannelIndex.vue'),
				}, 
				{
					path: '/admin/content/channel_add',
					name: 'channel_add',
					component: () => import('./views/admin/content/channel/ChannelAdd.vue'),
				}, 
				{
					path: '/admin/content/channel_edit',
					name: 'channel_edit',
					component: () => import('./views/admin/content/channel/ChannelEdit.vue'),
				}, 
				//内容管理-轮播图管理
				{
					path: '/admin/content/slideshowindex',
					name: 'slideshowindex',
					component: () => import('./views/admin/content/slideshow/SlideshowIndex.vue'),
				}, 
				//业务管理-业务景区
				{
					path: '/admin/business/scenicindex',
					name: 'scenicindex',
					component: () => import('./views/admin/business/scenic/ScenicIndex.vue'),
				}, 
				{
					path: '/admin/business/scenic_add',
					name: 'scenic_add',
					component: () => import('./views/admin/business/scenic/ScenicAdd.vue'),
				}, 
				{
					path: '/admin/business/scenic_edit',
					name: 'scenic_edit',
					component: () => import('./views/admin/business/scenic/ScenicEdit.vue'),
				}, 
				//业务管理-软件成果
				{
					path: '/admin/business/softwareindex',
					name: 'softwareindex',
					component: () => import('./views/admin/business/software/SoftwareIndex.vue'),
				}, 
				{
					path: '/admin/business/software_add',
					name: 'software_add',
					component: () => import('./views/admin/business/software/SoftwareAdd.vue'),
				}, 
				{
					path: '/admin/business/software_edit',
					name: 'software_edit',
					component: () => import('./views/admin/business/software/SoftwareEdit.vue'),
				}, 
				//新闻管理-文章管理
				{
					path: '/admin/news/articleindex',
					name: 'articleindex',
					component: () => import('./views/admin/news/ArticleIndex.vue'),
				},
				{
					path: '/admin/news/article_add',
					name: 'article_add',
					component: () => import('./views/admin/news/ArticleAdd.vue'),
				}, 
				{
					path: '/admin/news/article_edit',
					name: 'article_edit',
					component: () => import('./views/admin/news/ArticleEdit.vue'),
				}, 
				//招聘管理
				{
					path: '/admin/recruit/recruitindex',
					name: 'recruitindex',
					component: () => import('./views/admin/recruit/RecruitIndex.vue'),
				}, 
			]
		}
	]

})


router.beforeEach(async(to, from, next) => {
	if(to.path.includes('admin')){
		document.title = "三特科技信息管理系统"
	}
	if (!to.matched.some(r => r.meta.requireLogin)) {
		next();
	} else {
		if (token.get()) {
			next()
		} else {
			if (to.path === '/login') {
				next()
			} else {
				next('/login')
			}
		}
	}
})

export default router
