<template>
	<keep-alive>
		<router-view></router-view>
	</keep-alive>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
	html,body{
		margin: 0px;
		padding: 0px;
		height: 100%;
	}
	.el-menu{
		height: 100%;
	}
</style>
