import axios from 'axios'
import store from "../store";
import token from "../token";
const axiosInstance = axios.create({
	// baseURL: 'http://localhost:40000/',
	baseURL: 'http://123.60.46.9:40000/',
	timeout: 10000,
})


//请求拦截器配置
axiosInstance.interceptors.request.use(config => {
	// console.group('全局请求拦截')
	// console.log(config)
	// console.groupEnd()
	if (store.state.token) {
		config.headers['token'] = store.state.token
	}
	return config;
}, error => {
	return Promise.reject(error);
});

// 添加响应拦截器
axiosInstance.interceptors.response.use(response => {
	// console.group('全局响应拦截')
	// console.log(response)
	// console.groupEnd()
	let token = response.headers['token']
	if (token) {
		store.commit('setToken', token)
	}
	//获取后端返回的对象
	const res = response.data
	//请求成功，无系统错误
	if (response.status == 200) {
		if (res.code == 0) {//数据正常响应，且无业务逻辑错误
			return res;
		} else {
			return Promise.reject(res.msg)//数据正常响应，有业务逻辑错误
		}
	}
}, error => {
	return Promise.reject(error);
});

export default axiosInstance
