import axiosInstance from "./axiosInstance";
//用户登录
export function login(userName, passWord) {
	let param = {
		userName: userName,
		passWord: passWord
	}
	return axiosInstance.post('login/adminLogin', param)
}
//新增用户
export function create(param) {
	return axiosInstance.post('manager/addManager', param)
}
//编辑用户
export function update(param) {
	return axiosInstance.post('manager/editManager', param)
}
//删除用户
export function del(param) {
	return axiosInstance.post('manager/deleteManager', param)
}
//分页查询用户列表
export function query(param) {
	return axiosInstance.post('manager/queryManagerList', param)
}
//根据ID查询用户详情
export function detail(id) {
	let param={
		id:id
	}
	return axiosInstance.post('manager/queryManagerInfoByID', param)
}

export function upload(formData) {
	return axiosInstance.post('file/obsUploadFile', formData)
}
